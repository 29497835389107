// Timely colors
$color-blue-primary: #0f62fe;
$color-blue-light-alpha: #d0e2ff6c;

$color-grey: #707070;
$color-grey-mild: rgba(0, 0, 0, 0.12);
$color-grey-light: #f5f5f5;
$color-grey-lightest: #fafafa;

%noPrint {
  @media print {
    display: none !important;
  }
}

%noScreen {
  @media screen {
    display: none !important;
  }
}
