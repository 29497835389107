.lowerAlpha {
  list-style-type: lower-alpha;
}

.lowerRoman {
  list-style-type: lower-roman;
}

.listItemHeader {
  font-weight: bold;
  margin-right: 8px;
}

.caption {
  font-size: small;
  font-weight: lighter;
}

.sectionHeader {
  font-weight: bold;
}

.topHeader {
  font-weight: bolder;
  font-size: x-large;
}
