.timeDisplayText {
  font-size: 60px;
  font-weight: 200;
}

.timeButton {
  height: 72px;
}

.amPmDisplayText {
  font-size: 18px;
  font-weight: 200;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  align-items: center;
  height: 100px;
}

.selected {
  font-weight: 600;
}
