.container {
  width: 310px;
  height: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.grow {
  flex: 1;
}

.hide {
  display: none;
}

.column {
  display: flex;
  flex-direction: column;
}

.clockRow {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonRow {
  height: 50px;
}

.divider {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 15px;
  margin-top: 0;
}
