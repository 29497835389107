@import '../../../theme.scss';

.container {
  max-width: 1000px;
  width: 100%;

  @media (max-width: 1000px) {
    margin: 0 20px;
  }
}

.content {
  @media screen {
    margin-top: 176px;
  }
}
