.textField {
  margin-bottom: 20px;
}

.error {
  color: #b00020;
  font-weight: bold;
}

.forgotPasswordRegisterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
