.column {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 1000px;
  margin: 24px;
  align-content: center;
}

.header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card {
  max-height: 100vh;
  margin-top: 64px;
  margin-bottom: 64px;
  overflow: auto;
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: auto;
}

.rowReverse {
  display: flex;
  flex-direction: row-reverse;
  width: 500px;
  justify-content: space-between;
}

.textField {
  margin-bottom: 20px;
}

.error {
  color: #b00020;
  font-weight: bold;
}

.buttonRow {
  display: flex;
  flex-direction: row;
}

.submitButton {
  flex: 1;
}

.backButton {
  margin-right: 20px;
  width: 24px;
  padding-left: 6px;
  padding-right: 6px;
}

.backButton i {
  margin-right: 0 !important;
}
