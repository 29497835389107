.popup {
  padding: 8px 16px;
  background-color: #3e3d3d;
  color: white;
  flex: 1;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
}

.surface {
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: 0 0 0 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow {
  height: 14px;
}
