@import '../../../../theme.scss';

.container {
  @media screen {
    padding: 20px 20px 60px 20px;
  }

  @media print {
    padding-top: 20px;
    padding-left: 20px;
  }
}

.header {
  font-size: 1.17em;
  font-weight: 600px;
  margin-block-end: 6px;
}


