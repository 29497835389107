.column {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 1000px;
  margin: 24px;
  align-content: center;
}

.card {
  max-height: 100%;
  margin-top: 24px;
  margin-bottom: 100px;
  overflow: auto;
  z-index: 1;
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: auto;
}
