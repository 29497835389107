@import "node_modules/@fv-components/theme/src/_index.scss";

.container {
  a {
    display: block;
    margin-bottom: $fv-spacing-large;
    font-size: $fv-font-size-small;
    cursor: pointer;
    color: $fv-color-blue-default;

    &:hover {
      border-bottom: none;
      text-decoration: underline;
    }
  }

  // Amplify UI overrides
  input {
    &:focus {
      border: $fv-border-blue !important;
      box-shadow: 0 0 4px $fv-color-blue-default;
    }

    &:focus-visible {
      outline: none;
    }
  }

  button[type='submit'] {
    &:hover {
      box-shadow: inset 0 8px 2px #004057;
    }

    &:active {
      background-color: #00394d !important;
    }
  }

  button[data-test='sign-out-button'] {
    visibility: hidden;
  }

  button[data-test='sign-in-sign-in-button'] {
    &:disabled {
      font-size: 0px !important;
      background-color: $fv-color-grey-darker !important;

      &:hover {
        box-shadow: none;
      }

      &::after {
        font-size: $fv-font-size-default !important;
        content: 'Signing In...';
      }
    }
  }
}

.content {
  max-width: 340px;
  margin: 0 auto;

  .IDPBtn {
    width: 100%;
    margin-top: $fv-spacing-large;
    text-transform: initial;
    color: $fv-color-grey-darker;
    border: $fv-border-grey-lighter;
    background-color: white;

    &:hover {
      border-color: $fv-color-grey-darker;
    }
  }
}

.logo {
  margin: 60px auto 40px;
  width: 100%;
}

.shardOptions {
  text-align: center;

  .shardBtn {
    display: block;
    margin: $fv-spacing-large auto;
    width: 90%;
    min-height: 40px;
    background-color: $fv-color-blue-default;
    line-height: 24px;

    span {
      display: flex;
      justify-content: space-between;
      text-transform: initial;
    }
  }


  .customOption {
    display: flex;
    justify-content: space-between;
    margin: $fv-spacing-large auto;
    width: 90%;
    min-height: 40px;
  }
  
  .customOptionTitle {
    margin-top: 40px;
  }
  
  .customInputTeam {
    width: 130px;
    border-radius: $fv-border-radius-default 0 0 $fv-border-radius-default;
    border-width: $fv-border-width-default;
    border-color: $fv-color-grey-light;
    padding-right: $fv-spacing-small;
    padding-left: $fv-spacing-default;
    min-height: 36px;

    &:focus-visible {
      outline: none;
      border: $fv-border-default;
      border-radius: $fv-border-radius-default 0 0 $fv-border-radius-default;
    }
  }
  
  .customInputDomain {
    width: 100px;
    border-top-right-radius: $fv-border-radius-default;
    border-bottom-right-radius: $fv-border-radius-default;
    border-width: $fv-border-width-default;
    border-color: $fv-color-grey-light;
    border-left: none;
    padding-left: $fv-spacing-small;
    padding-right: $fv-spacing-small;
    min-height: 36px;
    background-color: rgba(0, 0, 0, 0.12);
  }
  
  .customNotFound {
    margin: $fv-spacing-large auto;
  
    a {
      display: inline;
    }
  }

  .customBtn {
    min-width: 40px;
    min-height: 40px;
    padding: 0;
    line-height: $fv-font-size-small;

    &:not([disabled]){
      background-color: $fv-color-blue-default;
    }
  }
}

.clearShardBtn {
  display: block;
  margin: 0 auto $fv-spacing-large;
  min-height: 40px;

  span {
    text-transform: initial;
    color: $fv-color-blue-default;
  }
}

.clearShardBtnIcon {
  margin-right: $fv-spacing-default;
  line-height: $fv-font-size-small;
  vertical-align: middle;
}

.connectLoading span {
  background-color: $fv-color-blue-default;
}
