.container {
  width: 315px;
  height: 330px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.grow {
  flex: 1;
}

.hide {
  display: none;
}

.column {
  display: flex;
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.spaceAround {
  align-content: space-around;
}
