@import '../../../../../theme.scss';

.container {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.07);
  position: relative;
}

.pin {
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: $color-blue-primary;
}

.squareMask {
  width: 100%;
  height: 100%;
  outline: none;
  position: absolute;
  user-select: none;
  touch-actions: none;
  pointer-events: auto;
}

.dragging {
  cursor: grabbing;
}

.pointer {
  left: calc(50%);
  width: 2px;
  top: calc(50%);
  position: absolute;
  background-color: $color-blue-primary;
  transform-origin: center top 0;
  height: 40%;
}

.clockNumber {
  left: calc((100% - 32px) / 2);
  top: calc((100% - 32px) / 2);
  width: 32px;
  height: 32px;
  display: inline-flex;
  position: absolute;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.selected {
  color: white;
}

.thumb {
  left: calc((100% - 32px) / 2);
  top: calc((100% - 32px) / 2);
  width: 4px;
  border: 14px solid $color-blue-primary;
  height: 4px;
  position: absolute;
  box-sizing: content-box;
  border-radius: 100%;
  background-color: #fff;
}

.thumbOnNumber {
  background-color: $color-blue-primary;
}
