.progress {
  width: 99.6%;
  margin: -4px auto 0;
  border-radius: 0 0 5px 5px;
}

.notRoundedProgress {
  width: 100%;
  margin: -4px 0 0;
}
