.serviceButton {
  @media screen {
    min-height: 24px;
    margin-bottom: 8px;
  }

  padding: 0 4px 0 4px;
  margin-left: 16px;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 0;
  background-color: white !important;
  height: unset;
  text-transform: none !important;
  text-align: left;
}

.checkIconReplacement {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
}
