@import '../../theme.scss';

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 72px;

  @media screen and (max-width: 600px) {
    display: none;
  }

  @media print {
    display: none;
  }

  pointer-events: none;
}

.footerContainer {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.footerContainer a {
  color: $color-grey;
  font-size: small;
  pointer-events: auto;
}
