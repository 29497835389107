@import "node_modules/@fv-components/theme/src/global.scss";
@import "./theme.scss";
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica Now Regular';
  src:
    local('Helvetica Now Regular'),
    local('HelveticaNowRegular'),
    url(./fonts/HelveticaNowText-Regular/font.woff) format('woff'),
    url(./fonts/HelveticaNowText-Regular/font.woff2) format('woff2');
}

@font-face {
  font-family: 'Helvetica Now Bold';
  src:
    local('Helvetica Now Bold'),
    local('HelveticaNowBold'),
    url(./fonts/HelveticaNowText-Bold/font.woff) format('woff'),
    url(./fonts/HelveticaNowText-Bold/font.woff2) format('woff2');
}

// Global MCWR overrides
:root {
  --mdc-theme-primary: #0f62fe;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-secondary: #0f62fe;
  // --mdc-theme-on-secondary:;
}

a {
  color: $color-blue-primary;
  text-decoration: none;
  border-bottom: none;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid $color-blue-primary;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-grey-mild;
}

.mdc-dialog--open {
  z-index: 12 !important;
}

.mdc-typography--caption {
  font-size: 14px !important;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $color-blue-primary !important;
}

.mdc-select:not(.mdc-slect-disabled).mdc-select--focused .mdc-floating-label {
  color: $color-blue-primary !important;
}

.mdc-button {
  border-width: 1px !important;
  text-transform: capitalize !important;
}

// MCWR Input not outlined or disabled or text area
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  background-color: $color-blue-light-alpha !important;
}

.mdc-text-field,
.mdc-select {
  background-color: #fff !important;
  z-index: 0 !important;
}

.mdc-notched-outline {
  z-index: 2;
}

@media not print {
  body {
    font-family: 'Helvetica Now Regular', sans-serif;
    background-color: $color-grey-light;
  }

  // Global MCWR font overrides
  .mdc-drawer__title,
  .mdc-list-item,
  .mdc-typography,
  .mdc-text-field-helper-text,
  .mdc-text-field__input {
    font-family: 'Helvetica Now Regular', sans-serif !important;
  }

  .mdc-button {
    font-family: 'Helvetica Now Bold', sans-serif !important;
  }
}

@media print {
  body {
    background-color: white;
  }
  ._pendo-badge {
    display: none !important;
  }
}

.mdc-top-app-bar--fixed-adjust {
  @media print {
    padding-top: 0 !important;
  }
}

.mdc-list-item__text:focus {
  outline: none !important;
}
