@import '../../../theme.scss';

.icon {
  outline-color: $color-blue-primary;
  direction: rtl;
  width: 24px;
  overflow: hidden;
}

.input {
  margin-right: 50px;
}

.textField {
  width: 100%;
}
