.tosLink {
  margin-left: 5px;
}

.tosPhrase {
  margin-top: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
