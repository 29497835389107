@import '../../../../../theme.scss';

.listItem {
  @media screen {
    margin-bottom: 24px;
  }

  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;

  @media print {
    page-break-inside: avoid;
  }
}

.listItem button {
  @media print {
    box-shadow: none;
    transition: none;
    color: black !important;
  }
}

.listItem button[aria-checked="false"] {
  @extend %noPrint;
}

.listItem > i {
  @extend %noPrint;
}

.listItemSelected {
  border-left: 6px solid $color-blue-primary;

  @media print {
    border-left: 0;
    border-bottom: 1px solid black;
  }
}

.listItemUnselected {
  border-left: 6px solid rgba(0, 0, 0, 0.25);

  @extend %noPrint;
}

.deadline {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
  margin-right: 20px;
}

.deadline:focus {
  outline-width: 0 !important;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  gap: 4px;
}

.serviceButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.serviceButton {
  height: 24px;
  padding: 0 4px 0 4px;
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 4px 4px;
}

.checkIconReplacement {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.checkbox {
  display: none;
}
