@import '../../../theme.scss';

.icon {
  outline-color: $color-blue-primary;
  direction: rtl;
  width: 24px;
  overflow: hidden;
}

.input {
  width: 200px;
  padding-right: 24px !important;

  /* yes i tried without it */
}

.textField {
  width: 250px;
}
