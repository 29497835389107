@import '../theme.scss';

.appBar {
  background: rgb(66, 66, 66);
  background: linear-gradient(90deg, rgba(66, 66, 66, 1) 0%, rgba(19, 19, 19, 1) 100%);

  @extend %noPrint;

  position: fixed !important;
  top: 0 !important;
  z-index: 10 !important;
}

.secondaryButton {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 0;
  background-color: white !important;
}

.printLogo {
  width: 100%;

  @extend %noScreen;
}

.printLogo path {
  fill: black;
}
