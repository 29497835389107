.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.divider {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 15px;
}

.prevNextButton {
  min-width: 24px;
}
