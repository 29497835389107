.column {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rowReverse {
  margin: 10px 0;
  display: flex;
  flex-direction: row-reverse;
  width: 500px;
}
