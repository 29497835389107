.row {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.grow {
  flex: 1;
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
