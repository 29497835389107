@import '../../theme.scss';

.row {
  min-height: 70px;
}

.content {
  max-width: 1000px;
  margin: 0 auto;
}

.deadlineList {
  @media screen {
    margin-top: 176px;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -3px 1px -2px rgba(0, 0, 0, 0.2), 0 -2px 2px 0 rgba(0, 0, 0, 0.14), 0 -1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: $color-grey-lightest;
  padding: 15px 10px 10px 10px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @extend %noPrint;
}

.footerContainer {
  max-width: 1000px;
  width: 100%;
}