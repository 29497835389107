.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media screen {
    min-height: 24px;
  }
}

.rightToLeft {
  flex-direction: row-reverse;
}
