@import '../../../theme.scss';

.container {
  position: fixed;
  // adjusting based on width because title bar adjusts height smaller on smaller width screens
  @media (max-width: 599)  {
    top: 56px;
  }
  @media (min-width: 600)  {
    top: 64px;
  }
  width: 100%;
  z-index: 4;
  background-color: $color-grey-lightest;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  @extend %noPrint;
}

